<template>
  <div class="p-grid" v-if="canAccess('titulares_read')">
    <div class=" p-col-12 card">
      <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
      <div class="p-col-12" style="padding-top: 20px">

        <div class="p-text-right p-fluid  p-ml-2">
          <div class="p-grid p-fluid  p-ml-2">
            <div class="p-field   p-fluid   p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedCountry" filter="true" :options="countries"
                                                 optionLabel="country"
                                                 @change="selectedCity=null;getData()"/>
                                        <label for="country">País</label>
                                    </span>
            </div>
            <div class="p-field   p-fluid  p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedCity" filter="true" :options="cities"
                                                 optionLabel="name"
                                                 @change="getData()"/>
                                        <label for="months">Ciudad</label>
                                    </span>
            </div>

            <div class="p-field p-fluid  p-mr-2">
                                  <span class="p-float-label">
                                        <Calendar id="start_date" :showIcon="true" v-model="from"
                                                  dateFormat="yy-mm-dd"
                                                  :yearNavigator="true" yearRange="2018:2050" class="p-inputtext-sm"
                                                  @date-select="getData()"/>
                                        <label for="start_date">Desde</label>
                                    </span>
            </div>
            <div class="p-field p-fluid  p-mr-2">
                                  <span class="p-float-label">
                                        <Calendar id="end_date" :showIcon="true" v-model="to"
                                                  dateFormat="yy-mm-dd"
                                                  :yearNavigator="true" yearRange="2018:2050" class="p-inputtext-sm"
                                                  @date-select="getData()"/>
                                        <label for="end_date">Hasta</label>
                                    </span>
            </div>
            <div class="p-field p-fluid  p-mr-2">
                   <span class="p-input-icon-left  p-mr-2">
                      <i class="pi pi-search"/>
                      <InputText v-model="query" placeholder="Buscar" @keypress.enter="getData"/>
                  </span>
            </div>
            <div class="p-field p-fluid  p-mr-2">
              <Button label="Agregar" icon="pi pi-plus" class="p-button-info p-mr-2"
                      @click="currentItem=null, editDialog = true"/>
            </div>
            <div class="p-field p-fluid  p-mr-2">
              <Button label="Sincronizar" icon="pi pi-refresh" class="p-button-success p-mr-2"
                      @click="sync"/>
            </div>
          </div>
        </div>

      </div>

      <div class="card card-w-title">

        <ProgressBar mode="indeterminate" v-if="processing"/>
        <DataTable :value="employeeList" :filters="filters"
                   class="p-datatable-responsive-demo p-datatable-sm p-datatable-striped"
                   v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                   :scrollable="isScrollable" @row-select="selectData($event)"
                   paginatorPosition="bottom" :rows="page.per_page" :lazy="true" :totalRecords="page.total"
                   :loading="processing" @page="onPage($event)"
                   responsiveLayout="scroll" v-model:expandedRows="expandedRows"
                   @row-expand="onRowExpand">

          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Lista de Titulares</h5>
            </div>
          </template>

          <Column :expander="true" headerStyle="width: 3rem"/>
          <Column field="id" header="#" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">#</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="dni" header="Cedula" headerStyle="width: 150px">
            <template #body="slotProps">
              <span class="p-column-title">Cédula</span>
              {{ slotProps.data.dni }}
            </template>
          </Column>
          <Column field="name" header="Nombre" headerStyle="width: 150px">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="last_name" header="Apellido" headerStyle="width: 150px">
            <template #body="slotProps">
              <span class="p-column-title">Apellido</span>
              {{ slotProps.data.last_name }}
            </template>
          </Column>
          <Column field="email" header="mail" headerStyle="width: 250px">
            <template #body="slotProps">
              <span class="p-column-title">Mail</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="phone" header="Teléfono" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">Teléfono</span>
              {{ slotProps.data.phone }}
            </template>
          </Column>
          <Column header="País" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">País</span>
              {{ slotProps.data?.country?.name }}
            </template>
          </Column>
          <Column header="Ciudad" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">Ciudad</span>
              {{ slotProps.data?.city?.name }}
            </template>
          </Column>
          <Column header="Fecha Creación" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">Fecha Creación</span>
              {{ slotProps.data?.created_at }}
            </template>
          </Column>
          <Column headerStyle="width: 150px">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success  p-mr-2"
                      @click="edit(slotProps.data)"/>
              <Button v-if="!slotProps.data.have_enrollment" icon="pi pi-trash"
                      class="p-button-rounded p-button-outlined p-button-danger  p-mr-2"
                      @click="confirmedDelete(slotProps.data.id)"/>
            </template>
          </Column>
          <template #expansion="slotProps">
            <ProgressBar mode="indeterminate" style="height: 2px" v-if="slotProps.data.loadingEnroll"/>
            <div class="orders-subtable" v-if="slotProps.data.enrollments">
              <h5>Matrículas de {{ `${slotProps.data.name}  ${slotProps.data.last_name}` }}</h5>
              <DataTable :value="slotProps.data.enrollments" responsiveLayout="scroll" row-hover="false">
                <Column field="sequential" header="N. matrícula" sortable></Column>
                <Column field="status" header="Estado" sortable></Column>
                <Column field="plan.name" header="Plan" sortable></Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
        <Sidebar v-model:visible="editDialog" :baseZIndex="1000" position="right" :style="'width:70%'"
                 class="custombar">
          <h3>{{ currentItem ? 'Editar titular' : 'Nuevo titular' }}</h3>
          <holder ref="holderForm" :holder="currentItem" :edit="true" :typeEmployees=typeEmployees
                  :typeHousing=typeHousing @cancel="editDialog = false" @ok="getData"></holder>
          <div class="p-grid p-jc-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveHolder"/>
          </div>
        </Sidebar>

        <Dialog v-model:visible="valDialogDeleteHolder" :style="{width: '450px'}"
                header="Confirmar" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span>Esta seguro que desea eliminar el titular?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text"
                    @click="valDialogDeleteHolder = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteHolder()"/>
          </template>
        </Dialog>

      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import service from '../../service/holder.service'
import enrollService from '../../service/enrollment.service'
import catalogService from '../../../core/service/catalog.service'
import bouncer from "../../../../helpers/bouncer";
import {defineAsyncComponent} from 'vue';

import {mapGetters, mapState} from "vuex";
import moment from "moment";

export default {
  mixins: [bouncer],
  refs: ['holderForm'],
  components: {
    holder: defineAsyncComponent(() => import('./Holder'))
  },
  data() {
    return {
      processing: false,
      editDialog: false,
      filters: {},
      typeEmployees: [],
      typeHousing: [],
      query: '',
      employeeList: null,
      currentItem: null,
      search: '',
      to: null,
      from: null,
      page: {
        page: 1,
        per_page: 10,
        total: 0,
      },
      window: {
        width: 0,
        height: 0
      },
      expandedRows: [],
      selectedCountry: null,
      selectedCity: null,
      countries: [],
      valDialogDeleteHolder: false,
      holderId: 0
    }
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  async mounted() {
    if (this.canAccess('titulares_read')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Verificación', url: "javascript:void(0);"},
        {label: 'Titulares', url: "javascript:void(0);"},
      ]);
      await this.getCountries();
      this.getData();
      catalogService.get('holders/types/employee').then(x => {
        this.typeEmployees = [];
        Object.keys(x.data).forEach(k => {
          this.typeEmployees.push({value: k, label: x.data[k]});
        });
        this.typeEmployees = Object.values(this.typeEmployees)
      });
      catalogService.get('holders/types/housing').then(x => {
        this.typeHousing = [];
        Object.keys(x.data).forEach(k => {
          this.typeHousing.push({value: k, label: x.data[k]});
        });
      });
    }
  },
  methods: {
    async getCountries() {
      await catalogService.get(`select/countries/cities`).then(response => {
        this.countries = response.data;
        if (!this.isAdmin) {
          this.selectedCountry = this.countries[0];
        }
      });
    },
    sync() {
      this.processing = true;
      service.synchronize({country_id: this.selectedCountry?.id}).then(() => {
        this.getData();
      }).catch(err => {
        if (err.response.status === 404) {
          this.$toast.add({
            severity: 'info',
            summary: 'Sincronización',
            detail: 'No existen registros por sinconizar',
            life: this.$utils.toastLifeTime()
          });
        } else {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        }
      }).finally(() => this.processing = false)
    },
    onPage(event) {
      this.page.page = event.page + 1;
      this.getData()
    },
    getData() {
      this.editDialog = false;
      this.processing = true;
      this.$nextTick(() => {
        service.getAll({
          ...this.page,
          country_id: this.isAdmin ? undefined : this.user?.employee?.country_id,
          q: this.query !== '' ? this.query : undefined,
          ...{'to': this.to ? moment(this.to).format("YYYY-MM-DD") : null},
          ...{'from': this.from ? moment(this.from).format("YYYY-MM-DD") : null},
          ...{'country_id': this.selectedCountry ? this.selectedCountry.id : null},
          ...{'city_id': this.selectedCity ? this.selectedCity.id : null},
        }).then(x => {
          this.employeeList = x.data.data;
          this.page.total = x.data.total;
          this.processing = false;

        })
      });
    },

    filter() {
    },
    selectData() {
      if (this.currentItem.country) {
        this.$store.dispatch('enroll/setCountry', this.currentItem.country);
        this.$router.push('enrollment/' + this.currentItem.id,);
        this.$route.params.pathMatch
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Información incompleta',
          detail: 'Por favor verifique los datos del titular',
          life: this.$utils.toastLifeTime()
        });
      }

    },
    edit(data) {
      this.currentItem = data;
      this.editDialog = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    saveHolder() {
      this.$refs.holderForm.$refs.holderForm.save().then(() => {
        this.getData()
      })
    },
    confirmedDelete(id) {
      this.valDialogDeleteHolder = true;
      this.holderId = id;
    },
    deleteHolder() {
      enrollService.deleteHolder(this.holderId).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Titular eliminado correctamente',
          life: this.$utils.toastLifeTime()
        });
        this.valDialogDeleteHolder = false;
        this.getData();
      }).catch((x) => {
        this.loading = false;
        const message = x.response.data;
        let error = '';
        Object.keys(message).forEach(key => {
          error += error + message[key] ? message[key] + '\n ' : '';
        });
        this.$toast.add({
          severity: 'error',
          summary: 'Error al registrar',
          detail: error,
          life: this.$utils.toastLifeTime()
        });
      });
    },
    onRowExpand(e) {
      e.data.loadingEnroll = !e.data.enrollments;
      enrollService.getByHolder(e.data.id).then(x => {
        e.data.enrollments = x.data;
      }).finally(() => {
        e.data.loadingEnroll = false
      })
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapState('auth', ['user']),
    ...mapGetters('bread', ['breadcrumb']),
    isScrollable() {
      return this.window.width > 800
    },
    cities() {
      return this.selectedCountry ? this.selectedCountry.cities : [];
    },
  }
}
</script>

<style lang="scss" scoped>
.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 50em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: .4rem;
          min-width: 30%;
          display: inline-block;
          margin: -.4em 1em -.4em -.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}

> > td {
  word-wrap: break-word !important
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-progressbar {
  height: 1px;
}


@media screen and (min-width: 50em) {
  .filter {
    width: 360px !important;
  }
}

::v-deep .p-dialog-content {
  height: 100%;
  overflow: auto !important;

}

</style>


